/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    min-height: auto;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  html, body {
    height: 100%;
  }
  
  /* Additional utility class for flex column layout */
  .flex-col-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  /* Menu animation */
.menu-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .menu-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .menu-exit {
    opacity: 1;
  }
  .menu-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  /* Add this to your existing global.css */
.relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
  }
  
  .z-10 {
    z-index: 10;
  }
    
/* @import "swiper/swiper-bundle.min.css";
 */
